import { QueryDefinitions } from '../store';
import * as tpch from "./tpch";
import * as tpcds from "./tpcds";
import * as ssb from "./ssb";
import * as job from "./job";
import * as ldbc from "./ldbc";
import * as uni from "./uni";
import * as clickbench from "./clickbench";
import * as stackoverflow_dba from "./stackoverflow_dba";
import * as stackoverflow_math from "./stackoverflow_math";

export const queries: QueryDefinitions = {
    "tpch": tpch.queries,
    "tpcds": tpcds.queries,
    "ssb": ssb.queries,
    "job": job.queries,
    "ldbc": ldbc.queries,
    "uni": uni.queries,
    "clickbench": clickbench.queries,
    "stackoverflow_dba": stackoverflow_dba.queries,
    "stackoverflow_math": stackoverflow_math.queries
}
